import { SystemStyleObject } from '@chakra-ui/react'

import { HANDLE_HOVERING_ATTR } from '../DragDrop/GlobalDragHandlePlugin'

export const SmartLayoutStyles: SystemStyleObject = {
  '.block-smartLayout': {
    // Prevent NodeViewContent divs from affecting grid/flex layouts
    '[data-node-view-content-outer="smartLayout"]': {
      display: 'contents',
    },
    '[data-node-view-content-inner="smartLayout"]': {
      display: 'contents',
    },
    '[data-selection-ring]': {
      // Border that appears on hover
      outlineStyle: 'none',
      outlineWidth: '1px',
      outlineColor: 'var(--grid-outline-color)',
      // Ensures + button is relative to the cell root
      position: 'relative',
    },
    // Hover
    [`&.is-focused, &:hover, &[${HANDLE_HOVERING_ATTR}]`]: {
      '.is-editable': {
        '[data-selection-ring]': {
          outlineStyle: 'solid',
        },
        '.add-button': {
          opacity: 1,
        },
      },
    },
  },
  '.node-smartLayoutCell': {
    // Prevent NodeViewWrapper divs from affecting grid/flex layouts
    display: 'contents',
    '> [data-node-view-wrapper]': {
      display: 'contents',
    },

    '.block-heading': {
      my: ['1em', '1em'],
    },
  },
}
